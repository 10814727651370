<div id="noResults">
  <img src="/images/seinfeld-speechless.gif" alt="gif of seinfeld being speechless" />
  <p>(server upravo sada jer niste odabrali lokaciju kina)</p>
</div>

<style>
  #noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.75rem;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
  }
  img {
    width: 100%;
    border-radius: 0.25rem;
    opacity: 0.75;
  }
  p {
    color: hsl(0, 0%, 75%);
    font-size: 0.75rem;
    font-weight: 400;
  }
</style>
